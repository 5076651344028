import './app.css';
import Text from '@uicl/ui-core/dist/Text';
import Avatar from '@uicl/ui-core/dist/Avatar';
import { Col } from '@uicl/ui-core/dist/ContentGrid';

function Footer() {
  return (
    <div className="footer">
      <Col sm={9}>
        <Text className="text-white font-13" variant="xSmall">
          © 2023 Change Healthcare LLC and/or one of its subsidiaries. <a className='text-white' href="https://www.changehealthcare.com/about">About Us</a> <a className='text-white' href="https://www.changehealthcare.com/privacy-notice">Privacy Notice</a>
        </Text>
      </Col>
      <Col sm={2}>
        <Text className="text-white text-right  font-13" variant="xSmall">
          Follow Change Healthcare:
        </Text>
      </Col>
      <Col sm={1}>
        <a href="https://www.facebook.com/changehealthcare/">
          <Avatar
          avatarImg="/static/media/icon-fb.png"
          className="footer-icons"
          dataTestId="test-avatar"
          variant="xSmall"
        />
        </a>
        <a href="https://twitter.com/Change_HC">
        <Avatar
          avatarImg="/static/media/icon-twitter.png"
          className="footer-icons"
          dataTestId="test-avatar"
          variant="xSmall"
        />
        </a>
        <a href="https://www.linkedin.com/company/960104/">
        <Avatar
          avatarImg="/static/media/icon-in.png"
          className="footer-icons"
          dataTestId="test-avatar"
          variant="xSmall"
        />
        </a>
      </Col>
    </div>
  );
}

export default Footer;
