import LoginButton from './login.js';
import Footer from './footer.js';
import Header from './header.js';
import './app.css';

function App() {
  return (
    <div>
      <Header />

      <div className="content">
        <LoginButton />
      </div>
      <Footer />
    </div>
  );
}

export default App;
