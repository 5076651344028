import Logo from '@uicl/ui-core/dist/Logo';
import './app.css';
import { Row, Col } from '@uicl/ui-core/dist/ContentGrid';

function Header() {
  return (
    <div className="header">
      <Row className="header-row">
      <Col sm={10}>
        <Logo
          dataTestId="test-logoDefault"
          domID="test-id"
          redirectPath="https://www.changehealthcare.com"
          logoURI="/static/media/logo.png"
        />
      </Col>
      <Col className="header-right" sm={2}>
        <a className='text-white' href='https://support.changehealthcare.com/'>Help & Support</a>
      </Col>
      </Row>
    </div>
  );
}

export default Header;
