import React, { useState } from 'react';
import Button from '@uicl/ui-core/dist/Button';
import Text from '@uicl/ui-core/dist/Text';
import Input from '@uicl/ui-core/dist/Input';
import DataItem from '@uicl/ui-core/dist/DataItem';
import { EyeBlocked, Eye } from './icons';
import SessionExpired from './SessionExpired';
import Error from './Error';

export default ChangePwd;

function ChangePwd() {
  const [password, setPassword] = useState('');
  const [togglePwdType, setTogglePwdType] = useState(false);
  const [toggleConfirmPwdType, setToggleConfirmPwdType] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isShowErrorPage, setIsShowErrorPage] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState({
    match: false,
    complexity: false,
    valid: false
  });

  const checkPasswordPolicy = (isFromMatch) => {
    // eslint-disable-next-line
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\s+]).{8,}$/;
    if (!regex.test(password)) {
      setInvalidPassword({ complexity: true, valid: false });
      setPasswordErrorMsg('This password does not meet the password policy');
    } else {
      if (isFromMatch) {
        setInvalidPassword({ match: false, complexity: false, valid: true });
      } else {
        setInvalidPassword({ complexity: false });
        checkPasswordMatch();
      }
    }
  };

  const checkPasswordMatch = () => {
    if (!password || !confirmPassword) {
      setInvalidPassword({ valid: false });
      return;
    }
    if (password !== confirmPassword) {
      setInvalidPassword({ match: true, complexity: false });
    } else {
      checkPasswordPolicy(true);
      //setInvalidPassword({ match: false, complexity: false, valid: true });
    }
  };

  const showPassword = (fieldName) => {
    if (fieldName === 'password') {
      setTogglePwdType(!togglePwdType);
    }
    if (fieldName === 'confirmPassword') {
      setToggleConfirmPwdType(!toggleConfirmPwdType);
    }
  };

  async function changePass() {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('sessionId')}`
      },
      body: JSON.stringify({ password })
    });
    if (response) {
      const respJson = await response.json();
      if (response.status === 200) {
        if (respJson.data && respJson.data.status === 'password set') {
          const destroyResponse = await fetch(`${process.env.REACT_APP_BACKEND}/session`, {
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${window.sessionStorage.getItem('sessionId')}`
            }
          });
          if (destroyResponse.status === 200) {
            const destroyResponseJson = await destroyResponse.json();
            // window.sessionStorage.clear();
            window.location.href = `${process.env.REACT_APP_UI_SSO}${window.sessionStorage.getItem(
              'resumePath'
            )}?REF=${destroyResponseJson.data.ref}`; // URL to redirect.
            window.sessionStorage.clear();
          } else if (destroyResponse.status === 401) {
            setIsSessionExpired(true);
          }
        }
      } else if ([400].includes(response.status)) {
        setInvalidPassword({ valid: false });
        setPasswordErrorMsg(respJson.error.message);
      } else if (response.status === 401) {
        setIsSessionExpired(true);
      } else if (response.status === 500) {
        setIsShowErrorPage(true);
      } else {
        setInvalidPassword({ valid: false });
        setPasswordErrorMsg(respJson.message);
      }
    }
  }

  const template = (
    <div className="content-inner auto-height">
      <div className="text-top pwd-header-text">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="xLarge">
          Create Password
        </Text>
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="medium">
          Create your password to access this application.
        </Text>
      </div>
      <div className="">
        <Input
          aria-label="Enter your new Password"
          dataTestId="password-inputButton"
          domID="password-id"
          label="New Password"
          placeholder="Enter Password"
          size="medium"
          icon={togglePwdType ? Eye : EyeBlocked}
          iconDomID="password-icon"
          onIconClick={() => {
            showPassword('password');
          }}
          name="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
            setInvalidPassword({ match: false, complexity: false });
          }}
          onBlur={() => {
            checkPasswordPolicy();
          }}
          onKeyUp={($event) => {
            if ($event.code === "Enter" && invalidPassword.valid) {
              changePass();
            } else {
              checkPasswordPolicy();
            }
          }}
          type={togglePwdType ? 'text' : 'password'}
          className="pwd-input"
          errorMessage={passwordErrorMsg}
          hasError={invalidPassword.complexity}
          initialValidationState={
            invalidPassword.valid ? 'valid' : invalidPassword.complexity ? 'invalid' : 'initial'
          }
        />

        <Input
          aria-label="Confirm New Password"
          dataTestId="confirmpassword-inputButton"
          domID="confirmpassword-id"
          label="Re-Enter New password"
          placeholder="Re-Enter New Password"
          size="medium"
          icon={!!toggleConfirmPwdType ? Eye : EyeBlocked}
          onIconClick={() => {
            showPassword('confirmPassword');
          }}
          iconDomID="password-icon"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
            setInvalidPassword({ match: false, complexity: false });
          }}
          onBlur={() => {
            checkPasswordMatch();
          }}
          onKeyUp={($event) => {
            if ($event.code === "Enter" && invalidPassword.valid) {
              changePass();
            } else {
              checkPasswordMatch();
            }
          }}
          type={toggleConfirmPwdType ? 'text' : 'password'}
          className="pwd-input"
          errorMessage="The passwords don't match"
          hasError={invalidPassword.match}
          initialValidationState={
            invalidPassword.valid ? 'valid' : invalidPassword.match ? 'invalid' : 'initial'
          }
        />
        <div className="pwd-policy-wrapper">
          <Text className="text-left" color="primary" variant="xSmallBold">
            PASSWORD POLICY
          </Text>

          <DataItem
            content={[
              '8 characters',
              '1 uppercase character (A-Z)',
              '1 lowercase character (a-z)',
              '1 number (0-9)',
              '1 special character ( ~ ! @ # $ % ^ & * ( ) - _ = + [ ] { } | ; : , . < > / ? )'
            ]}
            dataTestId="test-dataItemMulti"
            domID="test"
            label=" Passwords must have at least:"
            size="small"
            color="primary"
            className="text-left"
          />
        </div>
        <Button
          buttonType="standard"
          dataTestId="test-defaultButton"
          domID="automation-id"
          name="Create Password"
          onClick={changePass}
          size="medium"
          type="button"
          className="submit-btn width-100"
          disabled={!invalidPassword.valid}
        />
      </div>
    </div>
  );

  if (isSessionExpired) {
    return <SessionExpired />;
  } else if (isShowErrorPage) {
    return <Error />;
  }
  return template;
}
