import React, { useState, useEffect } from 'react';
import Button from '@uicl/ui-core/dist/Button';
import Text from '@uicl/ui-core/dist/Text';

function ResendOtp({ sendOtpCallBack, resendOtpMessage, isSendaNewCode }) {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    (() => {
      setUserName(window.sessionStorage.getItem('username'));
    })();
  }, []);

  function sendOTP() {
    sendOtpCallBack(true);
  }

  const template = (
    <div className="content-inner  auto-height">
      <div className="text-top">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="xLarge">
          {isSendaNewCode ? 'Send New Code' : 'Code Validation Failed'}
        </Text>
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="medium">
          {resendOtpMessage || ''} Send a new code to <b>{userName}</b>.
        </Text>
      </div>
      <div className="text-bottom text-center">
        <Button
          buttonType="standard"
          dataTestId="test-defaultButton"
          domID="automation-id"
          name="Send a New Code"
          onClick={sendOTP}
          size="medium"
          type="button"
          className="submit-btn width-100"
        />
      </div>
    </div>
  );

  return template;
}

export default ResendOtp;
