import React from 'react';
import ReactDOM from 'react-dom';
import UICLThemeProvider from '@uicl/ui-core/dist/UICLThemeProvider';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { fonts } from './fonts.js';
import App from './App';

const loadFonts = () => {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.innerHTML = fonts;
  head.appendChild(style);
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  }
]);

ReactDOM.render(
  <UICLThemeProvider theme="base">
    <RouterProvider router={router} />
  </UICLThemeProvider>,
  document.getElementById('root'),
  loadFonts
);
