import React from 'react';
import Text from '@uicl/ui-core/dist/Text';

function SessionExpired() {
  return (
    <div className="content-inner  auto-height">
      <div className="text-center">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="xLarge">
          Session Expired
        </Text>
      </div>
    </div>
  );
}

export default SessionExpired;
