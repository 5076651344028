import React, { useState } from 'react';
import Button from '@uicl/ui-core/dist/Button';
import Text from '@uicl/ui-core/dist/Text';
import Input from '@uicl/ui-core/dist/Input';
import SessionExpired from './SessionExpired';
import Error from './Error';

function VerifyOtp({ setOtpValidation, userName }) {
  const [otp, setOtp] = useState('');
  const [invalidOtp, setInvalidOtp] = useState(null);
  const [isOtpVerificationFailed, setIsOtpVerificationFailed] = useState(null);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [isDisableSubmitBtn, setIsDisableSubmitBtn] = useState(true);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isShowErrorPage, setIsShowErrorPage] = useState(false);

  async function validateOtp() {
    if (!invalidOtp) {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/otp?otp=${otp}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.sessionStorage.getItem('sessionId')}`
        }
      });
      if (response) {
        const respJson = await response.json();
        if (response.status === 200 && respJson.data && respJson.data.status === 'validated') {
          setOtpValidation({ isValidated: true, isResendOtp: false });
          setIsOtpVerificationFailed(false);
          setOtpErrorMessage('');
        } else if (response.status === 400 && respJson.error) {
          const errorObj = respJson.error;
          let errorMessage = `${errorObj.message}`;
          if (errorObj.message.indexOf('code already validated') > -1) {
            setOtpValidation({ isValidated: true, isResendOtp: false });
            setIsOtpVerificationFailed(false);
            setOtpErrorMessage('');
            return true;
          }
          if (errorObj.attemptsRemaining === 0 || errorObj.message.indexOf('code expired') > -1) {
            errorMessage =
              errorObj.message.indexOf('code expired') > -1 ? 'Your code expired.' : '';
            setOtpValidation({ isValidated: false, isResendOtp: true, resendOtpMessage: errorMessage });
            return true;
          }
          setIsOtpVerificationFailed(true);
          if (Object.hasOwn(errorObj, 'attemptsRemaining')) {
            errorMessage = `${errorMessage}.You have ${errorObj.attemptsRemaining} attempts left.`;
          }
          setOtpErrorMessage(errorMessage);
        } else if (response.status === 401) {
          setIsSessionExpired(true);
        } else if (response.status === 500) {
          setInvalidOtp(true);
          setIsShowErrorPage(true);
        }
      } else {
        setOtpValidation({ isValidated: false });
        setIsOtpVerificationFailed(true);
      }
    }
  }

  const sendNewCode = () => {
    setOtpValidation({ isValidated: false, isResendOtp: true, isSendaNewCode: true });
  };

  const onChangeHandler = (event) => {
    setOtp(event.target.value);
    if (event.target.value && event.target.value.length === 8) {
      setIsDisableSubmitBtn(false);
    } else {
      setIsDisableSubmitBtn(true);
    }
  };

  const onPasteHandler = ($event) => {
    let pastedText = undefined;
    if (window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData('Text');
    } else if ($event.clipboardData && $event.clipboardData.getData) {
      pastedText = $event.clipboardData.getData('text/plain');
    }
    // result must not exceed 8 long: 
    // + current length of the field
    // + pasted length 
    // - anything that is highlighted to replace
    const highlightedLength = window.getSelection().toString().length;
    if ($event.target.value.length + pastedText.length - highlightedLength > 8 ) {
      $event.preventDefault();
    }
  };

  const template = (
    <div className="content-inner auto-height">
      <div className="pwd-header-text">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="xLarge">
          Enter Security Code
        </Text>
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="medium">
          We sent a security code to {userName}. Please enter the code you received to create your
          password.
        </Text>
      </div>
      <div className="">
        <Input
          aria-label="Code"
          dataTestId="test-inputLabel"
          domID="with-label-test-id"
          label="Code"
          placeholder="Enter code"
          size="medium"
          type="text"
          name="otp"
          onKeyDown={($event) => {
            const highlightedLength = window.getSelection().toString().length;
            if (
              ($event.ctrlKey && $event.key == 'v') || // Allow Paste (Windows)
              ($event.metaKey && $event.key == 'v') || // Allow Paste (Mac)
              [8, 37, 39].includes($event.keyCode) || // Allow Delete and Arrow Keys
              ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes($event.key) // Allow Numbers
            ) {
              if ($event.target.value.length - highlightedLength >= 8 && ![8, 37, 39].includes($event.keyCode)) {
                // Allow arrow keys and delete when we're at 8 unhighlighted chars
                $event.preventDefault();
              }
            } else if ($event.code === 'Enter' && !isDisableSubmitBtn) {
              validateOtp();
            }  else {
              $event.preventDefault();
            }
          }}
          onPaste={onPasteHandler}
          value={otp}
          onChange={onChangeHandler}
          className="input"
          errorMessage={otpErrorMessage}
          hasError={invalidOtp || isOtpVerificationFailed}
        />
        <Button
          buttonType="standard"
          dataTestId="test-defaultButton"
          domID="automation-id"
          name="Submit Code"
          onClick={validateOtp}
          size="medium"
          type="button"
          className="submit-btn width-100"
          disabled={!otp || isDisableSubmitBtn}
        />
        <Button
          buttonType="diminished"
          dataTestId="test-defaultButton"
          domID="automation-id"
          name="Send a New Code"
          className="width-100 secondary-color"
          size="medium"
          type="button"
          onClick={sendNewCode}
        />
      </div>
    </div>
  );

  if (isSessionExpired) {
    return <SessionExpired />;
  } else if (isShowErrorPage) {
    return <Error />;
  }
  return template;
}

export default VerifyOtp;
