import React, { useState, useEffect, useRef } from 'react';
import Button from '@uicl/ui-core/dist/Button';
import Text from '@uicl/ui-core/dist/Text';
import ReCAPTCHA from 'react-google-recaptcha';
import VerifyOtp from './VerifyOtp';
import ChangePwd from './ChangePwd';
import ResendOtp from './ResendOtp';
import Error from './Error';
import SessionExpired from './SessionExpired';

function LoginButton() {
  const [userName, setUserName] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpSendInProgress, setOtpSendInProgress] = useState(false);
  const [isOtpSendFailure, setOtpSendFailure] = useState(false);
  const [isUserInfoFailure, setUserInfoFailure] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [resendOtpMessage, setResendOtpMessage] = useState(false);
  const [isCaptchVerified, setIsCaptchaVerified] = useState(false);
  const [isSendNewCode, setIsSendNewCode] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const reCaptchaRef = useRef(null);

  // get the initial params from ping
  const queryParams = new URLSearchParams(window.location.search);
  window.sessionStorage.setItem('ref1', queryParams.get('REF'));
  window.sessionStorage.setItem('resumePath', queryParams.get('resumePath'));

  async function sendOTP() {
    setOtpSendInProgress(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/otp`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionId}`
      },
      body:
        !resendOtp && !isSendNewCode
          ? JSON.stringify({ recaptchaToken: reCaptchaRef.current.getValue() })
          : JSON.stringify({})
    });
    if (response) {
      const respJson = await response.json();
      if (response.status === 200 && respJson.data.status === 'sent') {
        setResendOtp(false);
        setIsOtpSent(true);
      } else if (response.status === 401) {
        setIsSessionExpired(true);
      } else {
        // if the send didn't work, give up
        setOtpSendFailure(true);
      }
    }
    setOtpSendInProgress(false);
  }

  const loadUserInfo = async () => {
    if (
      window.sessionStorage.getItem('ref1') === 'null' ||
      window.sessionStorage.getItem('resumePath') === 'null'
    ) {
      return;
    }
    if (window.sessionStorage.getItem('ref1') && window.sessionStorage.getItem('resumePath')) {
      const url = `${process.env.REACT_APP_BACKEND}/session`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ref: window.sessionStorage.getItem('ref1'),
          resumePath: window.sessionStorage.getItem('resumePath')
        })
      });
      if (response) {
        const respJson = await response.json();
        if (respJson && respJson.data) {
          return { userName: respJson.data.email, token: respJson.data.id };
        }
      }
    }
  };

  function setOtpValidation({ isValidated, isResendOtp, resendOtpMessage, isSendaNewCode }) {
    setOtpValidated(isValidated);
    setResendOtp(isResendOtp);
    setResendOtpMessage(resendOtpMessage);
    setIsSendNewCode(isSendaNewCode);
  }

  function callResendOtp(isResendOtp) {
    if (isResendOtp) {
      sendOTP();
    }
  }

  function reCaptchaHandleChange() {
    if (reCaptchaRef.current.getValue()) {
      setIsCaptchaVerified(true);
    } else {
      setIsCaptchaVerified(false);
    }
  }

  let isLoading = false;

  useEffect(() => {
    (async () => {
      if (!isLoading) {
        isLoading = true;
        setUserName('Retrieving...');
        window.sessionStorage.removeItem('sessionId');
        window.sessionStorage.removeItem('username');
        const userInfo = await loadUserInfo();
        if (userInfo) {
          setUserName(userInfo.userName);
          window.sessionStorage.setItem('username', userInfo.userName);
          window.sessionStorage.setItem('sessionId', userInfo.token);
          setSessionId(userInfo.token);
        } else {
          setUserName('Not Found');
          setUserInfoFailure(true);
        }
      } else {
        setUserName('Not Found');
      }
    })();
  }, []);

  let template = (
    <div className="content-inner auto-height">
      <div className="text-top">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="xLarge">
          Send Security Code
        </Text>
      </div>
      <div className="text-top text-center">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="medium">
          Our records indicate you do not have a password.
        </Text>
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="medium">
          Send a code to the email:
        </Text>
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="mediumBold">
          {userName}
        </Text>
      </div>
      <div className="recaptcha">
        <ReCAPTCHA
          ref={reCaptchaRef}
          onChange={reCaptchaHandleChange}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          badge="inline"
        />
      </div>
      <div className="text-center">
        <Button
          buttonType="standard"
          indeterminate={true}
          indeterminateLine={false}
          shouldLoadResourceOnClick={true}
          awaitedResourceDidLoad={!isOtpSendInProgress}
          dataTestId="test-defaultButton"
          domID="automation-id"
          name="Send Code"
          onClick={sendOTP}
          onClickAfterResourceLoaded={sendOTP}
          size="medium"
          type="button"
          className="submit-btn width-100"
          disabled={!sessionId || !isCaptchVerified || isOtpSendInProgress}
        />
      </div>
    </div>
  );

  if (isSessionExpired) {
    template = SessionExpired();
  } else if (
    window.sessionStorage.getItem('ref1') === 'null' ||
    window.sessionStorage.getItem('resumePath') === 'null' ||
    isUserInfoFailure ||
    isOtpSendFailure
  ) {
    template = Error({});
  } else if (isOtpSent && !otpValidated && !resendOtp) {
    template = <VerifyOtp setOtpValidation={setOtpValidation} userName={userName} />;
  } else if (otpValidated) {
    template = <ChangePwd />;
  } else if (!otpValidated && resendOtp) {
    template = (
      <ResendOtp
        sendOtpCallBack={callResendOtp}
        resendOtpMessage={resendOtpMessage}
        isSendaNewCode={isSendNewCode}
      />
    );
  }

  return template;
}

export default LoginButton;
