import React from 'react';
import Text from '@uicl/ui-core/dist/Text';

function Error({ traceNumber }) {
  return (
    <div className="content-inner  auto-height">
      <div className="text-top">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="xLarge">
          You&apos;ve reached this page in error
        </Text>
      </div>
      <div className="text-botton text-center">
        <Text className="some-class-to-apply-custom-styles" color="primary" variant="medium">
          Please contact your administrator
        </Text>
      </div>
      {traceNumber ? (
        <div className="text-botton text-center">
          <Text className="some-class-to-apply-custom-styles" color="primary" variant="medium">
            Trace: {traceNumber}
          </Text>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default Error;
